import { Box, ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PagesComponent from "./components/PagesComponent";
import HomePage from "./pages/HomePage";
import { AppProvider } from "./providers/AppProvider";

function App() {
    return (
        <>
            <ChakraProvider>
                <style>
                    {`
                    :root {
                        --chakra-fonts-body: "muli" !important;
                        --chakra-fonts-heading: "muli" !important;
                        --chakra-radii-md: 22px;
                        --chakra-fontSizes-md: 14px;
                        --font-secondary: "Bebas Neue";
                        --chakra-space-4: 26px;
                        --chakra-sizes-10: 40px;
                        --color-red: #E4343F;
                    }

                    body {
                        overflow: hidden;
                    }

                    a {
                        outline: none;
                    }

                    @media only screen and (max-width: 480px) {
                        @supports (height: 100dvh) {
                            .details-container {
                                height: 100dvh;
                            }
                        }
                    }
                `}
                </style>
                <AppProvider>
                    <Box
                        position="relative"
                        height="100vh"
                        minH={["unset", "768px"]}
                    >
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route
                                    path="/conditions-generales-d-utilisation"
                                    element={<PagesComponent id={1} />}
                                />
                                <Route
                                    path="/politique-de-confidentialite"
                                    element={<PagesComponent id={2} />}
                                />
                                <Route
                                    path="/mentions-legales"
                                    element={<PagesComponent id={3} />}
                                />
                            </Routes>
                        </BrowserRouter>
                    </Box>
                </AppProvider>
            </ChakraProvider>
        </>
    );
}

export default App;
